<template>
    <section>
        <h1>Cargue</h1>
    </section>
</template>
<script>
    // import { computed } from 'vue'
    // import { useRoute } from 'vue-router'
    export default {
        setup () {
            // const route = useRoute()
            // const getActive = (routeNames = []) => {
            //     if (routeNames.includes(route.name)) {
            //     return 'active'
            //     }
            //     return ''
            // }
            // const menu = computed(() => {
            //     return [
            //         {
            //         id: 'facturacion-valor',
            //         to: { name: 'facturacion.masiva.factura-con-valor', params: { type: 'factura-con-valor' } },
            //         class: getActive([
            //             'facturacion.masiva.factura-con-valor'
            //         ]),
            //         label: 'Factura con valor'
            //         }
            //     ]
            // })
            return { }
            }

        }
</script>
<style scoped>
</style>
